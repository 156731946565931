const EyeClosed = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3686 15.4522C20.7074 13.365 21.9999 11 21.9999 11C21.9999 11 17.8749 3.4375 10.9999 3.4375C9.67939 3.44205 8.37377 3.71721 7.1637 4.246L8.22245 5.30612C9.11267 4.98184 10.0525 4.81481 10.9999 4.8125C13.9149 4.8125 16.3336 6.4185 18.1059 8.19087C18.9589 9.04845 19.7236 9.98955 20.3884 11C20.3087 11.1196 20.2207 11.2516 20.1203 11.396C19.6597 12.056 18.9791 12.936 18.1059 13.8091C17.8791 14.036 17.6426 14.2601 17.3951 14.4774L18.3686 15.4522Z"
        fill="#0B3838"
      />
      <path
        d="M15.5334 12.617C15.8402 11.7588 15.897 10.8311 15.6972 9.94183C15.4974 9.05258 15.0492 8.23835 14.4047 7.59388C13.7603 6.9494 12.946 6.50119 12.0568 6.30139C11.1675 6.10159 10.2398 6.15841 9.38162 6.46525L10.5132 7.59687C11.0417 7.52124 11.5805 7.56971 12.0869 7.73845C12.5933 7.90719 13.0535 8.19155 13.431 8.56901C13.8084 8.94648 14.0928 9.40666 14.2615 9.9131C14.4303 10.4195 14.4788 10.9583 14.4031 11.4867L15.5334 12.617ZM11.4867 14.4031L12.617 15.5334C11.7588 15.8402 10.8311 15.897 9.94183 15.6972C9.05258 15.4974 8.23835 15.0492 7.59388 14.4047C6.9494 13.7603 6.50119 12.946 6.30139 12.0568C6.10159 11.1675 6.15841 10.2398 6.46525 9.38162L7.59687 10.5132C7.52124 11.0417 7.56971 11.5805 7.73845 12.0869C7.90719 12.5933 8.19155 13.0535 8.56901 13.431C8.94648 13.8084 9.40666 14.0928 9.9131 14.2615C10.4195 14.4303 10.9583 14.4788 11.4867 14.4031Z"
        fill="#0B3838"
      />
      <path
        d="M4.60625 7.52131C4.35875 7.74131 4.12088 7.96405 3.894 8.19093C3.04101 9.0485 2.27634 9.98961 1.6115 11.0001L1.87962 11.3961C2.34025 12.0561 3.02088 12.9361 3.894 13.8092C5.66638 15.5816 8.08638 17.1876 11 17.1876C11.9845 17.1876 12.9113 17.0047 13.7775 16.6926L14.8362 17.7541C13.6262 18.2828 12.3206 18.558 11 18.5626C4.125 18.5626 0 11.0001 0 11.0001C0 11.0001 1.29113 8.63368 3.63138 6.54781L4.60487 7.52268L4.60625 7.52131ZM18.7632 19.7368L2.26325 3.23681L3.23675 2.26331L19.7368 18.7633L18.7632 19.7368Z"
        fill="#0B3838"
      />
    </svg>
  );
};

export default EyeClosed;
