import "../style/resetPassword.css";
import { useState } from "react";
import { app } from "../firebase/firebase.js";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import {
  checkPasswordContainsLowercase,
  checkPasswordContainsUppercase,
  checkPasswordHasNumber,
  checkPasswordLength,
  passwordAndConfirmationEqual,
} from "../helpers/passwordValidation.js";
import CheckFalse from "../assets/check-false.js";
import CheckTrue from "../assets/check-true.js";
import EyeOpen from "../assets/eye-open.js";
import EyeClosed from "../assets/eye-closed.js";
import LockIcon from "../assets/lock-icon.js";

const handleResetPassword = async (auth, oobCode, password) => {
  await confirmPasswordReset(auth, oobCode, password);
};

const handlePasswordValidation = (
  password,
  confirmation,
  setPasswordCondition,
  setPasswordHasError
) => {
  const length = checkPasswordLength(password);
  const containsUppercase = checkPasswordContainsUppercase(password);
  const containsLowercase = checkPasswordContainsLowercase(password);
  const hasNumber = checkPasswordHasNumber(password);
  const confirmed = passwordAndConfirmationEqual(password, confirmation);

  setPasswordCondition({
    length,
    containsUppercase,
    containsLowercase,
    hasNumber,
    confirmed,
  });
};

const handlePasswordError = (
  password,
  passwordCondition,
  setPasswordError,
  setPasswordHasError
) => {
  if (password === "") {
    setPasswordError("Required Field");
    setPasswordHasError(true);
    return;
  }
  if (!passwordCondition.length) {
    setPasswordError("Password must contain at least 6 characters");
    setPasswordHasError(true);
    return;
  }
  if (!passwordCondition.containsUppercase) {
    setPasswordError("Password must contain a uppercase letter");
    setPasswordHasError(true);
    return;
  }
  if (!passwordCondition.containsLowercase) {
    setPasswordError("Password must contain a lowercase letter");
    setPasswordHasError(true);
    return;
  }
  if (!passwordCondition.hasNumber) {
    setPasswordError("Password must contain a number");
    setPasswordHasError(true);
    return;
  }
};

const handlePasswordConfirmationError = (
  passwordConfirmation,
  passwordCondition,
  setPasswordConfirmationError,
  setPasswordHasError
) => {
  if (passwordConfirmation === "") {
    setPasswordConfirmationError("Required Field");
    setPasswordHasError(true);
    return;
  }
  if (!passwordCondition.confirmed) {
    setPasswordConfirmationError("Passwords must match");
    setPasswordHasError(true);
    return;
  }
};

const redirect = (url) => {
  window.location.replace(url);
};

function ResetPassword({ oobCode, continueUrl, locale }) {
  const { t } = useTranslation();
  const auth = getAuth(app);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [password, setPassword] = useState("");
  const [passwordCondition, setPasswordCondition] = useState({
    length: false,
    containsUppercase: false,
    containsLowercase: false,
    hasNumber: false,
    confirmed: false,
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState("");
  const [email, setEmail] = useState("");
  const [passwordSent, setPasswordSent] = useState(false);
  verifyPasswordResetCode(auth, oobCode)
    .then((email) => {
      setEmail(email);
    })
    .catch((err) => {
      console.log(err);
      return (
        <>
          <h1>Link Expired</h1>
        </>
      );
    });

  let inputPasswordClass = "input-true";
  let inputConfirmPasswordClass = "input-true";
  let passwordIcon = <EyeOpen />;
  let confirmPasswordIcon = <EyeOpen />;

  passwordError !== ""
    ? (inputPasswordClass = "input-false")
    : (inputPasswordClass = "input-true");

  passwordConfirmationError !== ""
    ? (inputConfirmPasswordClass = "input-false")
    : (inputConfirmPasswordClass = "input-true");

  showPassword ? (passwordIcon = <EyeClosed />) : (passwordIcon = <EyeOpen />);
  showPasswordConfirmation
    ? (confirmPasswordIcon = <EyeClosed />)
    : (confirmPasswordIcon = <EyeOpen />);

  return (
    <div className="container">
      {passwordSent ? (
        <div className="confirmation-container">
          <span>
            <LockIcon />
          </span>
          <h3 className="green-title">{t("password.confirmation.title")}</h3>
          <p className="green-text">{t("password.confirmation.text")}</p>
          {continueUrl !== "" ? redirect(continueUrl) : ""}
        </div>
      ) : (
        <>
          <div>
            <h3 className="main-title"> {t("title")}</h3>
            <hr />
          </div>
          <div>
            <p>
              Email
              <span style={{ marginLeft: "2px" }}>
                <b> {email}</b>
              </span>
            </p>
          </div>
          <form>
            <div className="input-container">
              <input
                className={inputPasswordClass}
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder={t("input.password.placeholder")}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordHasError(false);
                  setPasswordError("");
                  handlePasswordValidation(
                    e.target.value,
                    passwordConfirmation,
                    setPasswordCondition
                  );
                }}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                id="showPasswordIcon"
              >
                {passwordIcon}
              </span>
              {passwordHasError ? <p className="error">{passwordError}</p> : ""}
              <input
                name="confirm-password"
                type={showPasswordConfirmation ? "text" : "password"}
                className={inputConfirmPasswordClass}
                placeholder={t("input.confirmPassword.placeholder")}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                  setPasswordHasError(false);
                  setPasswordConfirmationError("");
                  handlePasswordValidation(
                    password,
                    e.target.value,
                    setPasswordCondition
                  );
                }}
              />
              <span
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
                id="showPasswordConfirmationIcon"
              >
                {confirmPasswordIcon}
              </span>
              {passwordHasError ? (
                <p className="error">{passwordConfirmationError}</p>
              ) : (
                ""
              )}
              <div className="password-conditions">
                <p>{t("verification.password")} </p>
                <ul>
                  <li>
                    {" "}
                    <p>
                      <span className="icon-container">
                        {passwordCondition.length ? (
                          <CheckTrue />
                        ) : (
                          <CheckFalse />
                        )}
                      </span>
                      {t("verification.length")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="icon-container">
                        {passwordCondition.containsUppercase ? (
                          <CheckTrue />
                        ) : (
                          <CheckFalse />
                        )}
                      </span>
                      {t("verification.uppercase")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="icon-container">
                        {passwordCondition.containsLowercase ? (
                          <CheckTrue />
                        ) : (
                          <CheckFalse />
                        )}
                      </span>
                      {t("verification.lowercase")}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="icon-container">
                        {passwordCondition.hasNumber ? (
                          <CheckTrue />
                        ) : (
                          <CheckFalse />
                        )}
                      </span>
                      {t("verification.number")}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="button-container">
                <button
                  onClick={(e) => {
                    handlePasswordError(
                      password,
                      passwordCondition,
                      setPasswordError,
                      setPasswordHasError
                    );
                    handlePasswordConfirmationError(
                      passwordConfirmation,
                      passwordCondition,
                      setPasswordConfirmationError,
                      setPasswordHasError
                    );
                    console.log("passwordHasError: ", passwordHasError);
                    if (!passwordHasError) {
                      setPasswordSent(!passwordSent);
                      console.log(passwordSent);
                      e.stopPropagation();
                      handleResetPassword(auth, oobCode, password);
                      return;
                    }
                    setPasswordSent(false);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {t("button.save")}
                </button>
                <p></p>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default ResetPassword;
